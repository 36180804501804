import React from "react"
import BlogCards from "../../../universal/components/BlogCards"
import "./index.scss"

const SliderBlog = () => {
  return (
    <div className="slider-blog">
      <div className="blog-items">
        <BlogCards />
      </div>
    </div>
  )
}

export default SliderBlog
