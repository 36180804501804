import React, { useState, useEffect } from "react"
import "./index.scss"

import { connect } from "react-redux"
import { Link } from "gatsby"
import { gaBlogClickEvent } from "../../../helpers/functions/googleAnalytics"

const BlogCards = ({ blogsList, oneBlog }) => {
  const [showMore, setShowMore] = useState(true)
  const [listOfBlogs, setListOfBlogs] = useState(null)
  const [index, setIndex] = useState(5)
  const [isHover, setHover] = useState(false)
  const [id, setId] = useState(null)

  const loadMore = () => {
    const newIndex = index + 5 - 1
    const newShowMore = newIndex < blogsList.length
    const newList = listOfBlogs.concat(blogsList.slice(index, newIndex))
    setIndex(newIndex)
    setListOfBlogs(newList)
    setShowMore(newShowMore)
  }

  const onMouseEnter = blog => {
    setId(blog.id)
    setHover(true)
  }

  const onMouseLeave = () => {
    setId(null)
    setHover(false)
  }

  const [windowWidth, setWindowWidth] = useState(0)

  const handleResize = () => {
    setWindowWidth(window && window.innerWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return function cleanup() {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  let location = window.location.pathname.length

  const [newBlogsList, setNewBlogsList] = useState([])

  useEffect(() => {
    setNewBlogsList(
      blogsList
        .filter(item => item !== oneBlog)
        .sort(() => 0.5 - Math.random())
        .slice(0, 4)
    )
  }, [blogsList])

  useEffect(() => {
    location = window.location.pathname.length
  }, [location])

  useEffect(() => {
    if (blogsList && location !== 1) {
      setListOfBlogs(blogsList.slice(1, 5))
    } else if (
      blogsList &&
      location === 1 &&
      windowWidth > 1024 &&
      windowWidth < 1173
    ) {
      setListOfBlogs(blogsList.slice(0, 3))
    } else {
      setListOfBlogs(blogsList.slice(0, 4))
    }
  }, [blogsList, location, windowWidth])

  if (listOfBlogs) {
    return (
      <>
        {location > 6
          ? newBlogsList.map((blog, i) => (
              <div
                key={i}
                onClick={() => {
                  gaBlogClickEvent(window.location.pathname === "/"? `Klik na blog (${blog.Slug}) sa pocetne stranice` : `Klik na blog (${blog.Slug}) sa povezanog clanka`)
                  window.location.href = `/blog/${blog.Slug}`
                }}
                style={{ cursor: "pointer" }}
                className={`${
                  location > 6 ? "post-link post-card" : "blog-link card"
                }`}
              >
                <div
                  className={`${location > 6 ? "post-card" : "card"}`}
                  onMouseEnter={() => onMouseEnter(blog)}
                  onMouseLeave={() => onMouseLeave(blog)}
                >
                  <img
                    src={`https://api.trebamosiguranje.hr${blog?.Slika.url}`}
                    alt="Blog Logo"
                    className={`${location > 6 ? "post-img" : "blog-img"}`}
                    onLoad={e => e.target.classList.add("active")}
                  />
                  <h6 className="card-title1">{blog.Kratki_Naslov}</h6>
                  <Link
                    to={`/blog/${blog.Slug}`}
                    className={`card-title2 ${
                      isHover && blog.id === id ? "active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                  >
                    {blog.Naslov}
                  </Link>
                </div>
              </div>
            ))
          : location.pathname === "/"
          ? listOfBlogs.map((blog, i) => (
              <div
                key={i}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  gaBlogClickEvent(window.location.pathname === "/"? `Klik na blog (${blog.Slug}) sa pocetne stranice` : `Klik na blog (${blog.Slug}) sa blog stranice`)
                  window.location.href = `/blog/${blog.Slug}`
                }}
                className={`${
                  location > 6 ? "post-link post-card" : "blog-link card"
                }`}
              >
                <div
                  className={`${location > 6 ? "post-card" : "card"}`}
                  onMouseEnter={() => onMouseEnter(blog)}
                  onMouseLeave={() => onMouseLeave(blog)}
                >
                  <img
                    src={`https://api.trebamosiguranje.hr${blog?.Slika.url}`}
                    alt="Blog Logo"
                    className={`${location > 6 ? "post-img" : "blog-img"}`}
                    onLoad={e => e.target.classList.add("active")}
                  />
                  <h6 className="card-title1">{blog.Kratki_Naslov}</h6>
                  <Link
                    to={`/blog/${blog.Slug}`}
                    className={`card-title2 ${
                      isHover && blog.id === id ? "active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                  >
                    {blog.Naslov}
                  </Link>
                </div>
              </div>
            ))
          : listOfBlogs.map((blog, i) => {
              return (
                <div
                  key={i}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    gaBlogClickEvent(window.location.pathname === "/"? `Klik na blog (${blog.Slug}) sa pocetne stranice` : `Klik na blog (${blog.Slug}) sa blog stranice`)
                    window.location.href = `/blog/${blog.Slug}`
                  }}
                  className={`${
                    location > 6 ? "post-link post-card" : "blog-link card"
                  }`}
                >
                  <div
                    className={`${location > 6 ? "post-card" : "card"}`}
                    onMouseEnter={() => onMouseEnter(blog)}
                    onMouseLeave={() => onMouseLeave(blog)}
                  >
                    <img
                      src={`https://api.trebamosiguranje.hr${blog?.Slika.url}`}
                      alt="Blog Logo"
                      className={`${location > 6 ? "post-img" : "blog-img"}`}
                      onLoad={e => e.target.classList.add("active")}
                    />
                    <h6 className="card-title1">{blog.Kratki_Naslov}</h6>
                    <Link
                      to={`/blog/${blog.Slug}`}
                      className={`card-title2 ${
                        isHover && blog.id === id ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      {blog.Naslov}
                    </Link>
                  </div>
                </div>
              )
            })}
        {showMore && (
          <button
            className="load-more-btn"
            onClick={() => {
              gaBlogClickEvent("Klik na Ucitaj jos")
              loadMore()
            }}
            style={{ cursor: "pointer" }}
          >
            Učitaj još
          </button>
        )}
      </>
    )
  } else return null
}

const mapStateToProps = (state, ownProps) => {
  return {
    blogsList: state.strapiReducer.blogsList,
    oneBlog: state.strapiReducer.oneBlog,
  }
}

export default connect(mapStateToProps, null)(BlogCards)
