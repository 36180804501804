import React from "react"
import "./index.scss"

import { convertMdLink } from "../../../helpers/functions"

const MarkDownContent = ({ content }) => {
  const handleMdx = content => {
    const textDecoration = item => {
      // const regex = /\#[a-zA-Z0-9._-\s]+|\#\#[a-zA-Z0-9._-\s]+|\#\#\#[a-zA-Z0-9._-\s]+|\#\#\#\#[a-zA-Z0-9._-\s]+|\#\#\#\#\#[a-zA-Z0-9._-\s]+|\#\#\#\#\#\#[a-zA-Z0-9._-\s]+/g
      // let newItem
      // if (regex.test(item)) {
      //   newItem = <h2 className="markdown-title">{item.replaceAll("#", "")}</h2>
      // } else {
      //   newItem = item
      //     .replaceAll(
      //       /\*\*\*[a-zA-Z0-9._-\s]+\*\*\*/g,
      //       e => "<strong><i>" + e.replaceAll("*", "") + "</i></strong>"
      //     )
      //     .replaceAll(
      //       /\*\*[a-zA-Z0-9._-\s]+\*\*/g,
      //       e => "<strong>" + e.replaceAll("*", "") + "</strong>"
      //     )
      //     .replaceAll(
      //       /\*[a-zA-Z0-9._-\s]+\*/g,
      //       e => "<i>" + e.replaceAll("*", "") + "</i>"
      //     )
      //     .replaceAll(
      //       /\~~[a-zA-Z0-9._-\s]+\~~/g,
      //       e => "<s>" + e.replaceAll("~", "") + "</s>"
      //     )
      //     .replaceAll(
      //       /\[(.*?)\]\(.*?\)/g,
      //       e => "<span>" + convertMdLink(e) + "</span>"
      //     )

      //   newItem = (
      //     <p
      //       className="markdown-desc"
      //       dangerouslySetInnerHTML={{ __html: newItem }}
      //     />
      //   )
      // }

      // return newItem

      let i = 0
      let arr = item.split(" ")
      let newArr = []
      let newItem

      while (i < arr.length) {
        let hashTagRegex = /\#[a-zA-Z0-9._-\s]+|\#\#[a-zA-Z0-9._-\s]+|\#\#\#[a-zA-Z0-9._-\s]+|\#\#\#\#[a-zA-Z0-9._-\s]+|\#\#\#\#\#[a-zA-Z0-9._-\s]+|\#\#\#\#\#\#[a-zA-Z0-9._-\s]+/g
        let threeAsterisksRegex = /\*\*\*[a-zA-Z0-9._-\s]+\*\*\*/g
        let twoAsteriskRegex = /\*\*[a-zA-Z0-9._-\s]+\*\*/g
        let oneAsterriskRegex = /\*[a-zA-Z0-9._-\s]+\*/g
        let tildesRegex = /\~~[a-zA-Z0-9._-\s]+\~~/g
        let linkRegex = /\[(.*?)\]\(.*?\)/g
        // let linkRegex = /^\[([\w\s\d]+)\]\(((?:\/|https?:\/\/)[\w\d./?=#]+)\)$/

        if (arr[i] === "") return null

        if (arr[i].includes("#")) {
          newItem = arr.slice(1, arr.length)
          newItem = newItem.toString().replace(/,/g, " ")
          newItem = <h2 className="markdown-title">{newItem}</h2>

          return newItem
        } else {
          if (threeAsterisksRegex.test(arr[i])) {
            newItem = arr[i].replace(/[*]/g, "")
            newItem = "<strong><i>" + newItem + "</i></strong>"
            newArr.push(newItem)
          } else if (twoAsteriskRegex.test(arr[i])) {
            newItem = arr[i].replace(/[*]/g, "")
            newItem = "<strong>" + newItem + "</strong>"
            newArr.push(newItem)
          } else if (oneAsterriskRegex.test(arr[i])) {
            newItem = arr[i].replace(/[*]/g, "")
            newItem = "<i>" + newItem + "</i>"
            newArr.push(newItem)
          } else if (tildesRegex.test(arr[i])) {
            newItem = arr[i].replace(/[~]/g, "")
            newItem = "<s>" + newItem + "</s>"
            newArr.push(newItem)
          } else if (linkRegex.test(arr[i])) {
            newItem = "<span>" + convertMdLink(arr[i]) + "</span>"
            newArr.push(newItem)
          } else {
            newArr.push(arr[i])
          }

          newItem = newArr.toString().replace(/,/g, " ")

          newItem = (
            <p
              className="markdown-desc"
              dangerouslySetInnerHTML={{ __html: newItem }}
            />
          )

          if (i == arr.length - 1) return newItem
        }

        i++
      }
    }

    let array = content.split("\n")
    let ret = []

    array.forEach(item => {
      ret.push(textDecoration(item))
    })

    return ret
  }

  return content ? handleMdx(content) : null
}

export default MarkDownContent
