import React, { useEffect, useState } from "react"
import SliderBlog from "../../../browserMobile/components/SliderBlog"
import BlogCards from "../../../universal/components/BlogCards"
import BlogPostRelatedTitle from "../../../universal/components/BlogPostRelatedTitle"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import "./index.scss"
import { gaBlogClickEvent } from "../../../helpers/functions/googleAnalytics"

const BlogPostRelatedArticles = () => {
  const location = useLocation()

  const [windowWidth, setWindowWidth] = useState(0)

  const handleResize = () => {
    setWindowWidth(window && window.innerWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return function cleanup() {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div className="blog-post-related-articles-container">
      {location.pathname !== "/" ? (
        <BlogPostRelatedTitle />
      ) : (
        <div className="landing-blog">
          <h3 className="landing-blog-title">Blog</h3>

          <Link className="landing-blog-title-2" to="/blog" onClick = {() => gaBlogClickEvent("Klik na Vidi vise")}>
            Vidi više
          </Link>
        </div>
      )}
      {windowWidth > 1024 ? (
        <div className="articles-holder">
          <BlogCards />{" "}
        </div>
      ) : null}

      {windowWidth < 1025 ? <SliderBlog /> : null}
    </div>
  )
}

export default BlogPostRelatedArticles
