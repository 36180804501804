import React, { useEffect, useState } from "react"
import strapi from "../api/strapi"
import Header from "../browser/components/Header"
import Footer from "../universal/components/Footer"
import BlogPostContent from "../views/BlogPostContent"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getBlogs, getOneBlog } from "../redux/actions/strapiActions"
import Loader from "../universal/components/Loader"
import { showCookieBanner } from "../helpers/functions"
import Cookies from "../universal/components/Cookies"

const BlogPost = ({ params, oneBlog, getOneBlog, blogsList, getBlogs }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    })

    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  useEffect(() => {
    if (!blogsList.length) {
      const fetchData = async () => {
        try {
          const responsePageData = await strapi.get("/blogs")

          getBlogs(responsePageData.data)
        } catch (err) {
          console.log(err.message)
        }
      }
      fetchData()
    }
  }, [params.id])

  useEffect(() => {
    document.title = `Dalmatiko osiguranje | ${oneBlog?.Naslov}`
  }, [oneBlog])

  if (blogsList.length) {
    getOneBlog(params.id)
  }

  return (
    <>
      {loading || !oneBlog ? (
        <Loader />
      ) : (
        <>
          <Header />
          <BlogPostContent />
          {showCookieBanner() ? <Cookies /> : null}
          <Footer />
        </>
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    blogsList: state.strapiReducer.blogsList,
    oneBlog: state.strapiReducer.oneBlog,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOneBlog: bindActionCreators(getOneBlog, dispatch),
    getBlogs: bindActionCreators(getBlogs, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogPost)
