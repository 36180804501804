import React, { useEffect, useState } from "react"
import infobip from "../../../assets/infobip.png"
import "./index.scss"

import { connect } from "react-redux"

const PostImage = ({ oneBlog, oneInsurance }) => {
  const [isBlogPath, setIsBlogPath] = useState(false)

  useEffect(() => {
    setIsBlogPath(window.location.pathname.includes("blog"))
  }, [window?.location.pathname])

  if (isBlogPath) {
    return (
      <>
        {!oneBlog?.Slika ? (
          <img
            src={infobip}
            alt="Post Image"
            className="blog-post-image"
            onLoad={e => e.target.classList.add("loaded")}
          />
        ) : (
          <img
            src={`https://api.trebamosiguranje.hr${
              oneBlog && oneBlog?.Slika?.url
            }`}
            alt="Post Image"
            className="blog-post-image"
            onLoad={e => e.target.classList.add("loaded")}
          />
        )}
      </>
    )
  } else {
    return (
      <>
        {!oneInsurance?.Slika ? (
          <img
            src={infobip}
            alt="Post Image"
            className="insurance-post-image"
            onLoad={e => e.target.classList.add("loaded")}
          />
        ) : (
          <img
            src={`https://api.trebamosiguranje.hr${
              oneInsurance && oneInsurance?.Slika?.url
            }`}
            alt="Post Image"
            className="insurance-post-image"
            onLoad={e => e.target.classList.add("loaded")}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    oneBlog: state.strapiReducer.oneBlog,
    oneInsurance: state.strapiReducer.oneInsurance,
  }
}

export default connect(mapStateToProps, null)(PostImage)
