import React, { useEffect, useState } from "react"
import MarkDownContent from "../MarkDownContent"
import "./index.scss"

import { connect } from "react-redux"

const PostText = ({ oneBlog, oneInsurance }) => {
  const [isBlogPath, setIsBlogPath] = useState(false)

  useEffect(() => {
    setIsBlogPath(window.location.pathname.includes("blog"))
  }, [window?.location.pathname])

  if (isBlogPath) {
    return (
      <div className="blog-post-text-holder">
        <MarkDownContent content={oneBlog?.Sadrzaj} />
      </div>
    )
  } else {
    return (
      <>
        {oneInsurance?.Kratki_Sadrzaj?.length ? (
          <div className="insurance-post-text-holder">
            {oneInsurance?.Kratki_Sadrzaj?.map((sadrzaj, i) => (
              <div key={i} className="insurance-text-image-container">
                <div className="insurance-text">
                  <h2 className="insurance-title">{sadrzaj?.Naslov}</h2>

                  <p className="insurance-desc">{sadrzaj?.Opis}</p>
                </div>

                <div className="insurance-img-holder">
                  <img
                    src={`https://api.trebamosiguranje.hr${sadrzaj?.Slika?.url}`}
                    alt="Bmw Image"
                    className="insurance-img"
                    onLoad={e => e.target.classList.add("loaded")}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    oneBlog: state.strapiReducer.oneBlog,
    oneInsurance: state.strapiReducer.oneInsurance,
  }
}

export default connect(mapStateToProps, null)(PostText)
