import React, { useEffect, useState } from "react"
import "./index.scss"
import { formatDate } from "../../../helpers/functions"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { openModal } from "../../../redux/actions/strapiActions"
import { gaOfferClickEvent } from "../../../helpers/functions/googleAnalytics"

const PostTitle = ({ oneBlog, oneInsurance, openModal, isOpenModal }) => {
  const [isBlogPath, setIsBlogPath] = useState(false)

  useEffect(() => {
    if (!isOpenModal) document.body.classList.remove("body-scroll")
    setIsBlogPath(window.location.pathname.includes("blog"))
  }, [window?.location.pathname, isOpenModal])

  if (isBlogPath) {
    return (
      <div className="blog-post-titles-holder">
        <h4 className="post-title1">{oneBlog?.Kratki_Naslov}</h4>

        <h2 className="post-title2">{oneBlog?.Naslov}</h2>

        <h4 className="post-title3">{formatDate(oneBlog?.published_at)}</h4>
      </div>
    )
  } else {
    return (
      <>
        <div className="insurance-post-titles-holder">
          <h4 className="post-title1">
            {oneInsurance?.kategorije_proizvoda?.Ime}
          </h4>

          <h2 className="post-title2">{oneInsurance?.Ime2}</h2>

          <button 
            className="offer-btn" 
            onClick={() => {
              gaOfferClickEvent("Klik na Zatrazi Ponudu sa stranice osiguranja (pozicija A)")
              openModal()
            }}
          >
            Zatraži ponudu
          </button>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    oneBlog: state.strapiReducer.oneBlog,
    oneInsurance: state.strapiReducer.oneInsurance,
    isOpenModal: state.strapiReducer.isOpenModal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModal: bindActionCreators(openModal, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostTitle)
