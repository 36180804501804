import React from "react"
import "./index.scss"

const EmptyBlogPost = () => {
  return (
    <div className="empty-blog-post">
      <h1 className="empty-blog-post-title">
        Trenutno nema dostupnih blogova.
      </h1>
    </div>
  )
}

export default EmptyBlogPost
