import React from "react"
import BlogPostRelatedArticles from "../../browser/components/BlogPostRelatedArticles"
import PostImage from "../../universal/components/PostImage"
import PostText from "../../universal/components/PostText"
import PostTitle from "../../universal/components/PostTitle"
import { connect } from "react-redux"
import "./index.scss"
import "../root_anims.scss"
import EmptyBlogPost from "../../universal/components/EmptyBlogPost"

const BlogPostContent = ({ blogsList }) => {
  return (
    <div className="blog-post-container">
      {blogsList?.length ? (
        <>
          <PostTitle />
          <PostImage />
          <PostText />
          <BlogPostRelatedArticles />
        </>
      ) : (
        <EmptyBlogPost />
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    blogsList: state.strapiReducer.blogsList,
  }
}

export default connect(mapStateToProps, null)(BlogPostContent)
